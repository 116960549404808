<template>
  <b-row class="hp-authentication-page">
    <left-item />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24">Iniciar Sesión</h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            Bienvenido de nuevo, inicie sesión en su cuenta.
          </p>

          <b-form class="mt-16 mt-sm-32 mb-8">
            <b-form-group label="Email :" label-for="email" class="mb-16">
              <b-form-input
                v-model="email"
                id="email"
                type="email"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Contraseña :"
              label-for="loginPassword"
              class="mb-16"
            >
              <b-form-input
                v-model="password"
                id="loginPassword"
                type="password"
              ></b-form-input>
            </b-form-group>

            <b-row align-v="center" align-h="between" class="mb-16">
              <b-col class="hp-flex-none w-auto">
                <b-form-checkbox v-model="rememberMe" @change="handleRemember"
                  >Recordar</b-form-checkbox
                >
              </b-col>

              <b-col class="hp-flex-none w-auto">
                <b-link
                  class="hp-button text-black-80 hp-text-color-dark-40"
                  to="/recover"
                >
                  Recuperar contraseña
                </b-link>
              </b-col>
            </b-row>

            <b-button
              @click="handleLogin"
              type="submit"
              value="login"
              variant="primary"
              class="w-100"
            >
              Iniciar sesión
            </b-button>
          </b-form>

          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";

import LeftItem from "../LeftItem.vue";
import FooterItem from "../FooterItem.vue";
import sweet from "@/logic/functions/sweetAlert";
import users from "@/logic/functions/users";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    LeftItem,
    FooterItem,
  },
  data: () => {
    return {
      email: "",
      password: "",
      rememberMe: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    async handleLogin(e) {
      e.preventDefault();

      this.$store
        .dispatch("auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          users.getCurrentUserProfile().then((response) => {
            let profileData = response.data;

            if (profileData.birth != null) {
              let date = new Date(profileData.birth);
              let formatter = new Intl.DateTimeFormat("es-CL", {
                dateStyle: "short",
              });
              let formattedDate = formatter.format(date);
              profileData.birth = formattedDate;
            }

            profileData.fullName =
              profileData.name +
              " " +
              profileData.last_name +
              " " +
              profileData.name2 +
              " " +
              profileData.last_name2;

            localStorage.setItem("home_app_user", JSON.stringify(profileData));
            this.handleRemember();
            this.$router.push("/dashboard");
          });
        })
        .catch((error) => {
          console.log(error);
          sweet.ToastMsg("", "error", "contraseña o correo incorrectos");
        });
    },

    async handleRemember(e) {
      if (this.rememberMe) {
          localStorage.setItem('rememberEmail', this.email);
        } else {
          localStorage.removeItem('rememberEmail');
        }
    },
  },
  mounted() {
    const rememberEmail = localStorage.getItem('rememberEmail');
    if (rememberEmail) {
      this.email = rememberEmail;
      this.rememberMe = true;
    }
  },
};
</script>
